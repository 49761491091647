/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import { useRoutes, useUrlGenerator } from '@folklore/routes';
import '@micromag/intl/locale/fr';
import Viewer from '@micromag/viewer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useLocation, useRoute } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/documents/micromag-document.module.css';

/* prettier-ignore */
import '../../styles/micromag.css';

// const Viewer = null;

const propTypes = {
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    micromag: PropTypes.shape({
        components: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
            }),
        ),
    }).isRequired,
    image: AppPropTypes.image,
    paused: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onComplete: PropTypes.func,
    onProgress: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    title: null,
    slug: null,
    image: null,
    paused: false,
    current: false,
    entered: false,
    onEnter: null,
    onLeave: null,
    onComplete: null,
    onProgress: null,
    className: null,
};

function MicromagDocument({
    id,
    title,
    slug,
    image,
    micromag,
    paused,
    entered,
    current,
    onEnter,
    onLeave,
    onComplete,
    onProgress,
    className,
}) {
    const url = useUrlGenerator();
    const { organisation = null, components = [] } = micromag || {};
    const { branding = null } = organisation || {};
    const routes = useRoutes();
    const [, params] = useRoute(routes.micromag);
    const { path: screenParam = null } = params || {};
    const screenIndex = parseInt(screenParam || '1', 10) - 1;
    const { id: currentScreenId = null } = components[screenIndex] || {};
    const basePath =
        slug !== null
            ? url('micromag', {
                  slug,
              })
            : null;
    const [, setLocation] = useLocation();
    const [screenViewed, setScreenViewed] = useState([]);
    const onScreenChange = useCallback(
        (screen) => {
            const newScreenIndex = components.findIndex(
                ({ id: screenId }) => screenId === screen.id,
            );

            let progress = null;
            if (screenViewed.indexOf(screen.id) === -1) {
                const newScreenViewed = [...screenViewed, screen.id];
                setScreenViewed(newScreenViewed);
                progress = newScreenViewed.length / components.length;
            }
            const lastIndex = components.length - 1;
            setLocation(
                url('micromag', {
                    slug,
                    path: newScreenIndex + 1,
                }),
            );
            if (newScreenIndex > 0 && newScreenIndex < lastIndex && onEnter !== null) {
                onEnter();
            } else if ((newScreenIndex === 0 || newScreenIndex === lastIndex) && onLeave !== null) {
                onLeave();
            }

            if (progress !== null && onProgress !== null) {
                onProgress(progress);
            }

            if (progress === 1 && onComplete !== null) {
                onComplete();
            }
        },
        [
            components,
            setLocation,
            slug,
            entered,
            onEnter,
            onLeave,
            screenViewed,
            onComplete,
            onProgress,
        ],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    // 'drag-x-disabled': entered,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.content}>
                {micromag !== null ? (
                    <Viewer
                        story={micromag}
                        theme={branding}
                        locale="fr"
                        // visitor={user}
                        paused={paused}
                        basePath={basePath}
                        screen={currentScreenId}
                        closeable={false}
                        className={styles.micromag}
                        trackingDisabled={!current}
                        neighborScreensActive={entered ? 1 : 0}
                        withoutRouter
                        withoutGestures
                        // withoutTransitions
                        // withoutGestures={!entered}
                        // withoutMenu
                        // withoutScreensMenu
                        // withoutShareMenu
                        // googleApiKey={googleApiKey}
                        // onViewModeChange={onViewModeChange}
                        onScreenChange={onScreenChange}
                        // onMenuChange={onMenuChange}
                        // onInteraction={onInteraction}
                        // onClose={onClose}
                        // onEnd={onEnd}
                        // withoutPlaybackControls={withoutPlaybackControls}
                        // withNavigationHint
                        // menuHeader={viewerMenuHeader}
                    />
                ) : null}
            </div>
        </div>
    );
}

MicromagDocument.propTypes = propTypes;
MicromagDocument.defaultProps = defaultProps;

export default MicromagDocument;
